<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    setSoute(path) {
      const newPath = `${path}Game`;
      if (this.$route.path !== newPath) {
        this.$router.push(newPath);
      }
    },
  },
  mounted() {
    this.$root.$on("change-route", this.setSoute);
  },
};
</script>

<style lang="scss"></style>
