<template>
  <div class="puzzle">
    <div class="puzzle__wrap" v-if="!isWinning">
      <div class="puzzle__head">
        <game-btn @btnEvent="returnToMain" :hasIcon="true" />
        <h1 class="puzzle__title">Собери пазл</h1>
      </div>

      <div class="puzzle__game">
        <div id="puzzle"></div>
      </div>
    </div>
    <win-view :itemData="puzzle" v-else></win-view>
  </div>
</template>

<script>
import headbreaker from "headbreaker";
import { puzzleSizes, breakpoints } from "../utils/puzzleSizesConfig";
export default {
  props: {
    puzzle: { type: Object, default: () => {} },
  },
  name: "PuzzleItem",
  data() {
    return {
      isWinning: false,
      windowWidth: 0,
      activeBreakpoint: null,
    };
  },
  watch: {
    windowWidth(width) {
      this.setActiveBreakpoint(width);
    },
    activeBreakpoint() {
      this.initGame();
    },
  },
  methods: {
    returnToMain() {
      this.$emit("return-to-main");
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setActiveBreakpoint(width) {
      if (width < breakpoints.mobileMini) {
        this.activeBreakpoint = "mobileMini";
      }
      if (width > breakpoints.mobileMini && width < breakpoints.mobile) {
        this.activeBreakpoint = "mobile";
      }
      if (width > breakpoints.mobile && width <= breakpoints.tablet) {
        this.activeBreakpoint = "tablet";
      }
      if (width > breakpoints.tablet) {
        this.activeBreakpoint = "desktop";
      }
    },
    initGame() {
      let img = new Image();
      img.src = require(`../assets/${this.puzzle.img}`);
      img.onload = () => {
        const puzzle = new headbreaker.Canvas("puzzle", {
          width: puzzleSizes[this.puzzle.imgType][this.activeBreakpoint].width,
          height:
            puzzleSizes[this.puzzle.imgType][this.activeBreakpoint].height,
          pieceSize:
            puzzleSizes[this.puzzle.imgType][this.activeBreakpoint].pieceSize,
          proximity: 10,
          strokeWidth: 7,
          strokeColor: "#0F4DF2",
          fixed: true,
          image: img,
          outline: new headbreaker.outline.Rounded(),
          preventOffstageDrag: true,
          painter: new headbreaker.painters.Konva(),
        });

        puzzle.adjustImagesToPuzzleHeight();
        puzzle.autogenerate({
          horizontalPiecesCount: puzzleSizes[this.puzzle.imgType].rowCount,
          verticalPiecesCount: puzzleSizes[this.puzzle.imgType].columnCount,
        });
        puzzle.shuffleGrid();
        puzzle.draw();
        puzzle.attachSolvedValidator();
        puzzle.onValid(() => {
          setTimeout(() => {
            // this.isWinning = true;
          }, 500);
        });
      };
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.initGame();
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";

.puzzle {
  overflow: hidden;
  &__wrap {
    padding: 0 10px;
    @include mobile {
      padding: 0;
    }
  }

  &__head {
    margin: 0 auto;
    position: relative;
    max-width: 500px;
    margin: 35px auto 0;
    display: flex;
    align-items: center;
    @include tablet {
      max-width: 380px;
    }
    @include mobile-mini {
      max-width: none;
      width: 100%;
      padding: 0 10px;
    }
  }

  &__arrow {
    height: 50px;
    cursor: pointer;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__title {
    margin: 0;
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-family: "PolymerDisplay";
    @include tablet {
      font-size: 32px;
    }
    @include mobile {
      font-size: 26px;
    }
  }

  &__game {
    margin: 0 auto 35px;
    width: fit-content;
  }
}
</style>
