<template>
  <div class="puzzle-main">
    <h1 class="puzzle-main__title" v-if="!activePuzzle">Выбери пазл</h1>
    <div class="puzzle-main__wrap" v-if="!activePuzzle">
      <div
        class="puzzle-main__item"
        v-for="(puzzle, idx) in puzzles"
        :key="`puzzle-${idx}`"
        @click="chooseImg(puzzle)"
      >
        <img
          :src="require(`../assets/${puzzle.img}`)"
          alt=""
          class="puzzle-main__item-img"
        />
      </div>
    </div>
    <puzzle-item
      :puzzle="activePuzzle"
      @return-to-main="returnToMain"
      v-else
    ></puzzle-item>
  </div>
</template>

<script>
export default {
  name: "PuzzleGame",
  data() {
    return {
      puzzles: [
        {
          title: "Лунный скафандр «Кречет»",
          desc: "В космическом скафандре «Кречет» 1969 года советский космонавт должен был первым ступить на поверхность Луны. Система обеспечения скафандра позволяла провести на Луне рекордно долгое время — до 10 часов, а в нештатной ситуации космонавт мог находиться в нём до 52 часов. <br> Скафандр так и не исполнил своего предназначения: в 1969 году американцы высадились на Луну и пилотируемая лунная программа в СССР была свёрнута. Но решения, найденные при разработке «Кречета», легли в основу современных российских скафандров для работы в открытом космосе.",
          imgType: "vertical",
          img: "11.png",
          name: "space-suit",
        },
        {
          title: "Легковой автомобиль «Руссо-Балт»",
          desc: "«Руссо-Балт» К 12/20 — единственный сохранившийся легковой автомобиль, сделанный в Российской империи. Его выпустил Русско-Балтийский завод в Риге в 1911 году.<br>Автомобиль сначала использовали в гатчинской Офицерской воздухоплавательной академии, потом — в Государственном московском тресте мелкой и средней промышленности. В 30-е годы у автомобиля был частный хозяин. Потом его выкупила Горьковская киностудия. А уже от неё машина после реставрации попала в коллекцию Политехнического музея.",
          imgType: "square",
          img: "10.png",
          name: "russo-balt",
        },
        {
          title: "Электрическая лампа накаливания системы Томаса Эдисона",
          desc: "В середине XIX века учёные провели эксперименты по накаливанию электричеством проволоки и поняли, что этот принцип можно использовать в конструкциях ламп. Но это были лишь эксперименты. <br>В 1870-х годах лампы попали в Америку. Их увидел Томас Эдисон. Он улучшил технологию откачки воздуха из колбы и подобрал подходящий материал для нити накаливания. В Политехническом музее хранится лампа 1880-х годов самой первой эдисоновской конструкции.",
          imgType: "vertical",
          img: "9.png",
          name: "lamp",
        },
      ],
      activePuzzle: null,
    };
  },
  methods: {
    chooseImg(item) {
      this.activePuzzle = item;
    },
    returnToMain() {
      this.activePuzzle = null;
    },
  },
  mounted() {
    this.$root.$on("return-to-main", this.returnToMain);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";

.puzzle-main {
  &__title {
    margin: 35px 0;
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-family: "PolymerDisplay";
    @include tablet {
      font-size: 32px;
      margin: 20px 0 20px;
    }
    @include mobile {
      font-size: 26px;
      margin: 15px 0 15px;
    }
  }

  &__wrap {
    max-width: 1070px;
    margin: 35px auto 0;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    padding: 0 30px;
    @include desktop-small {
      max-width: 970px;
    }
    @include mobile {
      flex-direction: column;
      padding: 0 15px;
    }
  }

  &__item {
    position: relative;
    width: 28%;
    height: 250px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
    position: relative;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
    background: #005cff;
    &:hover {
      transition: 0.3s;
      height: 255px;
    }
    @include tablet {
      height: 230px;
    }
    @include mobile {
      width: 255px;
      margin-bottom: 16px;
    }
  }

  &__item-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;

    &:hover {
      transition: 0.3s;
      width: 350px;
    }
  }
}
</style>
