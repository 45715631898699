<template>
  <ul class="games">
    <li class="games__item" v-for="(game, idx) in games" :key="`game-${idx}`">
      <game-btn
        @btnEvent="changeRoute(game.route)"
        :label="game.label"
        prefix="fill"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "GamesList",
  data() {
    return {
      games: [
        { route: "Puzzle", label: "Пазл" },
        { route: "Memory", label: "Найди пару" },
      ],
    };
  },
  methods: {
    changeRoute(routeParam) {
      this.$root.$emit("change-route", routeParam);
    },
  },
};
</script>

<style scoped lang="scss">
.games {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 678px;
  margin: 100px auto;
  list-style-type: none;
  padding: 0;

  &__item {
    margin-bottom: 40px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
