<template>
  <div class="memory">
    <!-- сменить на v-if="!isWinning" после теста -->
    <div class="memory__main" v-if="!isWinning && !currentItem">
      <div class="memory__head">
        <h1 class="memory__title">Найди пару</h1>
        <div class="memory__timer" v-if="start">
          Время: <span v-if="min">{{ min }} мин </span>
          <span v-if="sec">{{ sec }} сек</span>
        </div>
      </div>
      <div class="memory__wrap">
        <memory-card
          v-for="(card, idx) in memoryCards"
          :key="`card-${idx}`"
          :cardData="card"
          @click.native="flipCard(card)"
          :isFlipped="card.isFlipped"
        />
      </div>
      <div class="memory__wrap memory__wrap_bot" v-if="start">
        <game-btn
          @btnEvent="restartGame"
          label="Начать сначала"
          prefix="text"
        />
        <div class="memory__steps" v-if="steps">Шагов: {{ steps }}</div>
      </div>
      <h5 class="memory__text">
        Сыграй несколько раз и ты узнаешь историю разных экспонатов нашего музея
      </h5>
      <!-- убрать после теста -->
      <div class="memory__select-wrap">
        <p>Для отображения финального экрана выберите экспонат</p>
        <select class="memory__item-select" v-model="currentItem">
          <option
            v-for="(item, idx) in cards"
            :key="`item-${idx}`"
            :value="item"
          >
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>
    <!-- сменить на v-else после теста  и убрать currentItem -->
    <win-view
      v-if="currentItem || isWinning"
      :itemData="currentItem || showedItem"
    ></win-view>
  </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
export default {
  name: "MemoryGame",
  data() {
    return {
      cards: [
        {
          name: "lightning-detector",
          img: "1.png",
          title: "Грозоотметчик конструкции Александра Попова",
          desc: "Грозоотметчик — одно из первых устройств, принимавших электромагнитные волны. Устройство было создано в конце 1890-х годов. Прибор регистрировал активность атмосферных электрических разрядов — молний. В момент приёма сигнала включался звонок. Конструктор этого устройства — Александр Попов — известен как один из изобретателей радио. ",
        },
        {
          name: "typewriter",
          img: "2.png",
          title: "Пишущая машина Odell",
          desc: "В конце XIX века полноценные пишущие машинки были роскошью. Для тех, кто был небогат, существовали варианты попроще — индексные пишущие машины, такие как эта пишущая машинка Odell 1891–1894 годов. У индексных пишущих машин нет клавиатуры — чтобы напечатать один символ, нужно было найти его среди остальных, передвинуть указатель и нажать на рычажок. Печатать таким способом было медленно и неудобно. Зато конструкция этого устройства была простой, надёжной и дешёвой. ",
        },
        {
          name: "tricycle",
          img: "3.png",
          title: "Трицикл «Кудель»",
          desc: "В конце XIX века многие изобретатели пытались собрать моторный экипаж с бензиновым двигателем. Одной из таких конструкций стал трицикл — трёхколесный велосипед с мотором. По сравнению с дорогими автомобилями трициклы были очень дёшевы. К тому же к трициклу можно было присоединить прицеп или пассажирскую коляску. ",
        },
        {
          name: "phenomobile",
          img: "4.png",
          title: "«Феномобиль»",
          desc: "Первые модели «Феномобиля» были чем-то средним между мотоциклом и автомобилем. У этого «Феномобиля» 1911 года вместо руля длинный Г-образный рычаг, в обиходе прозванный «бычьим хвостом». И всего три колеса. Не самый мощный двигатель — от шести лошадиных сил — размещался над единственным ведущим колесом. «Феномобиль» был недорог и пользовался спросом.",
        },
        {
          name: "airplane",
          img: "5.png",
          title: "Модель самолёта Сикорского «Илья Муромец»",
          desc: "В 1913 году никто не верил, что такой четырёхтонный гигант может подняться в воздух. Первый экспериментальный полёт выполнил сам конструктор — в то время студент Санкт-Петербургского Политехнического института Игорь Сикорский. «Илья Муромец» стал первым в мире самолётом с отдельным от кабины салоном, спальными комнатами и даже ванной с туалетом. ",
        },
        {
          name: "bicycle-lamp",
          img: "6.png",
          title: "Керосиновый велосипедный фонарь Search Light",
          desc: "Этот фонарь для велосипедов был создан в конце XIX века в США. Внутри фонаря — миниатюрная керосиновая горелка. Одной заправки фонаря керосином хватало на шесть часов. Велофонарь давал тусклый оранжевый свет.",
        },
        {
          name: "microscope",
          img: "7.png",
          title: "Микроскоп Гука — Маршала",
          desc: "Микроскопы XVII века давали лишь десятикратное увеличение. Ясное изображение можно было получить только в центре. По краям картинка теряла чёткость, а белый свет раскладывался в радугу. Роберт Гук поместил между линзами микроскопа диафрагму — пластину с маленьким отверстием. Изображение стало более чётким, но и более тёмным. Тогда Гук добавил в конструкцию систему освещения: масляную лампу, сосуд с водой и линзу. Такая конструкция давала увеличение уже до 150 раз.",
        },
        {
          name: "steam-engine",
          img: "8.png",
          title: "Передвижная паровая машина «Локомобиль»",
          desc: "Такие локомобили делали на рубеже XIX–XX веков. Передвижные паровые двигатели позволяли существенно увеличить мощность всевозможных механизмов. В сельском хозяйстве при помощи локомобилей приводили в движение молотилки, веялки, мельницы и насосы. ",
        },
      ],
      memoryCards: [],
      flippedCards: [],
      start: false,
      steps: 0,
      totalTime: {
        minutes: 0,
        seconds: 0,
      },
      isWinning: false,
      gameCount: 0,
      currentItem: null, // убрать после теста
    };
  },
  computed: {
    sec() {
      return this.totalTime.seconds;
    },
    min() {
      return this.totalTime.minutes;
    },
    showedItem() {
      return this.currentItem
        ? this.currentItem
        : this.cards[this.gameCount - 1] || null;
    },
  },
  methods: {
    flipCard(card) {
      if (card.isMatched || card.isFlipped || this.flippedCards.length === 2)
        return;

      if (!this.start) {
        this._startGame();
      }

      card.isFlipped = true;

      if (this.flippedCards.length < 2) this.flippedCards.push(card);
      if (this.flippedCards.length === 2) this._match();
    },
    _startGame() {
      this._tick();
      this.interval = setInterval(this._tick, 1000);
      this.start = true;
    },
    _tick() {
      if (this.totalTime.seconds !== 59) {
        this.totalTime.seconds++;
        return;
      }

      this.totalTime.minutes++;
      this.totalTime.seconds = 0;
    },
    _match() {
      this.steps++;

      if (this.flippedCards[0].name === this.flippedCards[1].name) {
        setTimeout(() => {
          this.flippedCards.forEach((card) => (card.isMatched = true));
          this.flippedCards = [];

          if (this.memoryCards.every((card) => card.isMatched === true)) {
            clearInterval(this.interval);
            this.isWinning = true;
            this.setGameCount();
          }
        }, 400);
      } else {
        setTimeout(() => {
          this.flippedCards.forEach((card) => {
            card.isFlipped = false;
          });
          this.flippedCards = [];
        }, 800);
      }
    },
    restartGame() {
      clearInterval(this.interval);

      this.cards.forEach((card) => {
        Vue.set(card, "isFlipped", false);
        Vue.set(card, "isMatched", false);
      });

      setTimeout(() => {
        this.memoryCards = [];
        this.memoryCards = _.shuffle(
          this.memoryCards.concat(
            _.cloneDeep(this.cards),
            _.cloneDeep(this.cards)
          )
        );
        this.totalTime.minutes = 0;
        this.totalTime.seconds = 0;
        this.start = false;
        this.isWinning = false;
        this.steps = 0;
        this.flippedCards = [];
      }, 300);
    },
    getImgUrl(pic) {
      return require("../assets/" + pic);
    },
    setGameCount() {
      if (this.gameCount < this.cards.length) {
        this.gameCount++;
      } else {
        this.gameCount = 0;
        this.gameCount++;
      }
    },
    returnToMain() {
      this.isWinning = false;
      //убрать после теста
      this.currentItem = null;
      this.restartGame();
    },
  },
  created() {
    this.cards.forEach((card) => {
      Vue.set(card, "isFlipped", false);
      Vue.set(card, "isMatched", false);
    });

    this.memoryCards = _.shuffle(
      this.memoryCards.concat(_.cloneDeep(this.cards), _.cloneDeep(this.cards))
    );
  },
  mounted() {
    this.$root.$on("return-to-main", this.returnToMain);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/base/mixins.scss";
.memory {
  @include mobile-mini {
    padding: 0 10px;
  }
  &__main {
    width: 780px;
    margin: 35px auto;
    padding: 0 10px;
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 640px;
    }
    @include mobile {
      max-width: 450px;
      width: 100%;
      padding: 0;
    }
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    position: relative;
    align-items: center;
    &_bot {
      margin-top: 15px;
    }
  }

  &__head {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    height: 80px;
    position: relative;
    @include tablet {
      height: 75px;
    }
    @include mobile {
      height: 60px;
    }
  }
  &__title {
    margin: 0 0 10px;
    font-size: 36px;
    text-align: center;
    font-family: "PolymerDisplay";
    @include tablet {
      font-size: 32px;
    }
    @include mobile {
      font-size: 26px;
    }
  }

  &__timer {
    font-size: 20px;
    @include desktop-small {
      font-size: 18px;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  &__steps {
    font-size: 20px;
    @include desktop-small {
      font-size: 18px;
    }
    @include mobile {
      font-size: 14px;
    }
  }

  &__select-wrap {
    width: 100%;
    select {
      width: 100%;
    }
  }

  &__text {
    font-family: "PolymerDisplay";
    font-size: 24px;
    text-align: center;
    margin: 15px 0;
    @include mobile {
      font-size: 16px;
      margin: 15px 10px;
    }
  }
}
</style>
